<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('monitor_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="120" ref="form" :model="form" :rules="rules">
        <FormItem label="指标名称" prop="name">
          <Input
            style="width: 300px"
            placeholder="请输入指标名称"
            v-model="form.name"
          ></Input>
        </FormItem>
        <FormItem label="指标类型" prop="category">
          <Select
            v-model="form.category"
            placeholder="请选择指标类型"
            style="width: 300px"
          >
            <Option
              v-for="(item, index) in catList"
              :key="index"
              :value="item.id + '/' + item.name"
              >{{ item.name }}</Option
            ></Select
          >
        </FormItem>
        <FormItem label="检测对象" prop="label">
          <!-- <Input
            style="width: 300px"
            placeholder="请输入检测对象"
            v-model="form.label"
          ></Input> -->
          <Select
            v-model="form.label"
            style="width: 300px"
            placeholder="请选择检测对象"
          >
            <Option
              v-for="(item, index) in labelList"
              :key="index"
              :value="item.name"
              >{{ item.name }}</Option
            >
          </Select>
          <!-- <Select
            v-model="form.category"
            placeholder="请选择指标类型"
            style="width: 300px"
          >
            <Option
              v-for="(item, index) in catList"
              :key="index"
              :value="item.id + '/' + item.name"
              >{{ item.name }}</Option
            ></Select
          > -->
        </FormItem>
        <FormItem label="检测标准值" prop="standardValue">
          <Input
            style="width: 300px"
            placeholder="请输入检测标准值"
            v-model="form.standardValue"
          ></Input>
          <!-- <Select
            v-model="form.category"
            placeholder="请选择指标类型"
            style="width: 300px"
          >
            <Option
              v-for="(item, index) in catList"
              :key="index"
              :value="item.id + '/' + item.name"
              >{{ item.name }}</Option
            ></Select
          > -->
        </FormItem>
        <FormItem label="要求">
          <Input
            style="width: 300px"
            type="textarea"
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "指标名称",
            minWidth: 200,
            key: "name",
          },
          {
            title: "指标类型",
            key: "categoryName",
          },
          {
            title: "检测对象",
            align: "center",
            key: "label",
          },
          {
            title: "检测标准值",
            align: "center",
            key: "standardValue",
          },
          {
            title: "要求",
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("monitor_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("monitor_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增指标",
            ca: "monitor_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "指标",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        categoryId: "",
        categoryName: "",
        name: "",
        remark: "",
        category: "",
        label: "",
        standardValue: "",
      },
      rules: {
        name: [{ required: true, message: "请填写指标名称" }],
        category: [{ required: true, message: "请选择指标类型" }],
        // label: [{ required: true, message: "请填写检测对象" }],
        // standardValue: [{ required: true, message: "请填写检测标准值" }],
      },
      search_data: {},
      //指标类型列表
      catList: [],
      //检测对象列表
      labelList: [],
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增指标信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("monitor_check")) return;
      this.table.loading = true;
      this.$post(this.$api.MONITORINGINDICATOR.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.category = row.categoryId + "/" + row.categoryName;
      this.modal = {
        show: true,
        title: "编辑指标信息",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.MONITORINGINDICATOR.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      let params = this.form;
      if (this.form.category) {
        params.categoryId = this.form.category.split("/")[0];
        params.categoryName = this.form.category.split("/")[1];
      }
      let url;
      if (params.id) {
        url = this.$api.MONITORINGINDICATOR.UPDATE;
      } else {
        url = this.$api.MONITORINGINDICATOR.ADD;
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    //指标类型
    getCategory() {
      this.$post(this.$api.CAR_MANAGE.TYPE, {
        pid: 247,
      }).then((res) => {
        this.catList = res.list;
      });
    },
    // 检测对象 -- 旱作，水作，蔬菜
    getLabel() {
      this.$post(this.$api.CAR_MANAGE.TYPE, {
        pid: 296,
      }).then((res) => {
        this.labelList = res.list;
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        categoryId: "",
        categoryName: "",
        name: "",
        remark: "",
        category: "",
        label: "",
        standardValue: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
    this.getLabel();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>